/* eslint-disable react/jsx-no-target-blank */
import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import Layout from "../components/layout"

const IndexPage = (): JSX.Element => {
  const images = useStaticQuery(graphql`
    query {
      homeBenefits: file(relativePath: { eq: "home-benefits-img.jpg" }) {
        publicURL
      }
      addUploadDoc: file(relativePath: { eq: "add-upload-doc.svg" }) {
        publicURL
      }
      addRecipients: file(relativePath: { eq: "add-recipients.svg" }) {
        publicURL
      }
      manageDocuments: file(relativePath: { eq: "manage-documents.svg" }) {
        publicURL
      }
      signersConsent: file(relativePath: { eq: "signers-consent.svg" }) {
        publicURL
      }
      realEstate: file(relativePath: { eq: "real-estate.jpg" }) {
        publicURL
      }
      finance: file(relativePath: { eq: "finance.png" }) {
        publicURL
      }
      legal: file(relativePath: { eq: "legal.png" }) {
        publicURL
      }
      humanResources: file(relativePath: { eq: "human-resources.png" }) {
        publicURL
      }
      site {
        siteMetadata {
          lang
          title
          description
          author
          siteUrl
          contentUrl
        }
      }
    }
  `)

  const schema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: images.site.siteMetadata.title,
    description: images.site.siteMetadata.description,
    logo:
      "https://documentesign.com/static/ca8aed1a72c0bfcca722e15da72ada1e/document-eSign-logo.svg",
    address: {
      "@type": "PostalAddress",
      addressCountry: "USA",
      addressLocality: "San Diego",
      addressRegion: "CA",
      postalCode: "92101",
      streetAddress: "2535 Kettner Blvd.,Suite 3-A2",
    },
    email: "contact@documentesign.com",
    telephone: "+1-619-752-3485",
    url: "https://documentesign.com/",
    sameAs: [
      "https://twitter.com/documentesign/",
      "https://www.facebook.com/documentesign/",
      "https://instagram.com/documentesign",
      "https://www.linkedin.com/company/documentesign/",
      "https://www.pinterest.com/documentesignn/",
    ],
  }

  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-sidebar.min.js"))

  useScript(withPrefix("js/sticky-sidebar.js"))

  useScript(withPrefix("js/sticky-header.js"))

  return (
    <>
      <Layout>
        <SEO
          title="100% Free Document eSign Software Online"
          description="Document eSign is a cloud-based electronic signature solution to sign, audit, manage ,track &amp; esign docs 60% faster."
          schemaMarkup={schema}
        />
        <div className="intro home-intro ani-in show-on-scroll">
          <div className="container">
            <div className="row">
              <div className="col-md-7 pt-lg-5 mt-lg-4 ani-top">
                <h1 className="pt-5">
                  100% Free <br /> Document eSign Software
                </h1>
                <div className="h4 mt-4 pt-1 fw-regular">
                  Easy, secure, and effective document eSignature solutions
                </div>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-dark mt-4 mb-2 px-md-5"
                >
                  Signup now &#8594;
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5 home-benefits">
          <div className="ani-in show-on-scroll">
            <div className="text-center mb-5">
              <h2 className="mb-5">
                Electronic signatures are fast and easy with Document eSign
              </h2>
              <div className="benefit-screenshot">
                <img
                  data-src={images.homeBenefits.publicURL}
                  className="img-fluid lazyload"
                  alt="home-benefits"
                  width="860"
                  height="464"
                />
              </div>
            </div>
          </div>
          <div className="row pt-lg-4">
            <div className="col-md-6 ani-in show-on-scroll">
              <div className="d-flex mb-4">
                <div className="icons flex-shrink-0">
                  <i className="fas fa-signature"></i>
                </div>
                <div className="flex-grow-1 pl-4">
                  <div className="h4">Easy to use digital signature</div>
                  <p>
                    Skip printing, scanning, faxing to collect signatures,
                    upgrade to Document eSign’s perfect esign solution to sign
                    your documents digitally with just a few clicks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 ani-in show-on-scroll">
              <div className="d-flex mb-4">
                <div className="icons flex-shrink-0">
                  <i className="fas fa-user-plus"></i>
                </div>
                <div className="flex-grow-1 pl-4">
                  <div className="h4">Invite signers made easy</div>
                  <p>
                    Invite your partners, customers and employees for document
                    signing in just two clicks. Get their legally binding
                    signatures in just minutes and close your deals Faster.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-lg-4">
            <div className="col-sm-6 ani-in show-on-scroll">
              <div className="d-flex mb-4">
                <div className="icons flex-shrink-0">
                  <i className="fas fa-file-contract"></i>
                </div>
                <div className="flex-grow-1 pl-4">
                  <div className="h4">Extensive template library</div>
                  <p>
                    Automate your{" "}
                    <Link to="/free-document-esign-online/">PDF eSign </Link>{" "}
                    process to save countless hours on manual paperwork. Present
                    yourself professionally by hassle free coordination of
                    singing documents.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 ani-in show-on-scroll">
              <div className="d-flex mb-4">
                <div className="icons flex-shrink-0">
                  <i className="fas fa-pen-nib"></i>
                </div>
                <div className="flex-grow-1 pl-4">
                  <div className="h4">Sign the way you want</div>
                  <p>
                    Generate signatures that are either drawn, typed or import
                    it as a picture. Collect eSignatures that are legally
                    binding from any device. Document eSign ensures a safest way
                    to close a deal.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 ani-in show-on-scroll">
              <div className="d-flex mb-4">
                <div className="icons flex-shrink-0">
                  <i className="fas fa-shield-alt"></i>
                </div>
                <div className="flex-grow-1 pl-4">
                  <div className="h4">Seamless integration</div>
                  <p>
                    Document eSign is flexibly integrated with a broad suite of
                    your favorite third party applications like – Salesforce,
                    Dropbox, Google Drive, Microsoft Dynamics.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 ani-in show-on-scroll">
              <div className="d-flex mb-4">
                <div className="icons flex-shrink-0">
                  <i className="fas fa-cogs"></i>
                </div>
                <div className="flex-grow-1 pl-4">
                  <div className="h4">Complete security</div>
                  <p>
                    Send and receive contracts, agreements, and other eSign
                    documents with utmost security &amp; ensures a safest way to
                    close a deal sining{" "}
                    <Link to="/free-document-esign-online/">
                      document esign online.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-md-5">
          <div className="free-forever">
            <h2 className="text-center">eSign documents online for free</h2>
            <div className="free-forever-list row row-cols-1 row-cols-md-3 mt-5 mb-4">
              <div className="col">
                <div className="d-flex align-items-center free-forever-list-item">
                  <div className="flex-shrink-0 free-forever-list-icon">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="flex-grow-1 free-forever-list-text">
                    Unlimited documents
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center free-forever-list-item">
                  <div className="flex-shrink-0 free-forever-list-icon">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="flex-grow-1 free-forever-list-text">
                    Unlimited templates
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center free-forever-list-item">
                  <div className="flex-shrink-0 free-forever-list-icon">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="flex-grow-1 free-forever-list-text">
                    Multiple signers
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center free-forever-list-item">
                  <div className="flex-shrink-0 free-forever-list-icon">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="flex-grow-1 free-forever-list-text">
                    Secure audit trail
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center free-forever-list-item">
                  <div className="flex-shrink-0 free-forever-list-icon">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="flex-grow-1 free-forever-list-text">
                    Cloud integration support
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center free-forever-list-item">
                  <div className="flex-shrink-0 free-forever-list-icon">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="flex-grow-1 free-forever-list-text">
                    Alerts and notifications
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <a href="/pricing/" className="btn btn-dark px-md-5">
                Signup now &#8594;
              </a>
            </div>
          </div>
        </div>
        <div className="container py-md-5">
          <div className="row py-lg-4">
            <div className="col-lg-5 sticky-sidebar-container">
              <div className="sticky-sidebar">
                <div className="sticky-sidebar-inner min-h-inner">
                  <h2 className="show-on-scroll ani-in">
                    How <br />
                    Document eSign <br />
                    works
                  </h2>
                  <p className="mt-4 pt-md-1 show-on-scroll ani-in">
                    Sign important documents with a solution that fits right
                    into your existing workflow &amp; skip the complicated
                    approval process. Get started with our free electronic
                    signature service with and creates secure, legally binding
                    e-signatures from any device.
                  </p>
                  <a
                    href="https://app.documentesign.com/auth/signup"
                    target="_blank"
                    className="btn btn-dark mt-4 mb-2 px-md-5 show-on-scroll ani-in"
                  >
                    Signup now &#8594;
                  </a>
                  <br />
                  <small className="text-muted">
                    <strong>Free forever,</strong> No credit card required
                  </small>
                </div>
              </div>
            </div>
            <div className="col-lg-7 mt-5 mt-sm-0">
              <div className="row">
                <div className="col-sm-6 ani-in show-on-scroll ">
                  <div className="card border-0 shadow-sm text-center mb-4">
                    <div className="p-4">
                      <img
                        data-src={images.addUploadDoc.publicURL}
                        className="card-img-top lazyload"
                        alt="Add/upload document"
                      />
                    </div>

                    <div className="card-body">
                      <div className="h4">Add / Upload document</div>
                      <p className="card-text">
                        Create a new document or work on the existing templates,
                        and upload the document that needs to be signed.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 ani-in show-on-scroll">
                  <div className="card border-0 shadow-sm text-center mb-4">
                    <div className="p-4">
                      <img
                        data-src={images.addRecipients.publicURL}
                        className="card-img-top lazyload"
                        alt="Add recipients"
                      />
                    </div>

                    <div className="card-body">
                      <div className="h4">Add recipients</div>
                      <p className="card-text">
                        Add the number of signees, sign the document yourself if
                        needed by typing, drawing on your device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 ani-in show-on-scroll">
                  <div className="card border-0 shadow-sm text-center mb-4">
                    <div className="p-4">
                      <img
                        data-src={images.manageDocuments.publicURL}
                        className="card-img-top lazyload"
                        alt="Manage documents"
                      />
                    </div>

                    <div className="card-body">
                      <div className="h4">Manage documents</div>
                      <p className="card-text">
                        Manage your esign documents by tracking the activities
                        between the signers, keeping malpractices at bay
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 ani-in show-on-scroll mb-4">
                  <div className="card border-0 shadow-sm text-center">
                    <div className="p-4">
                      <img
                        data-src={images.signersConsent.publicURL}
                        className="card-img-top lazyload"
                        height="180"
                        alt="Add/upload document"
                      />
                    </div>
                    <div className="card-body">
                      <div className="h4">Signer’s consent</div>
                      <p className="card-text">
                        Recipients draw, type or upload their signatures on your
                        documents from any device at their disposal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-md-5 py-4 mb-lg-5">
          <div className="ani-in show-on-scroll">
            <h2 className="text-center mt-4">
              Streamline digital transformation for all industries{" "}
            </h2>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 mt-5 mb-lg-4">
            <div className="col ani-in show-on-scroll mb-4">
              <div className="card border-0">
                <Link to="/real-estate/" className="stretched-link">
                  <img
                    data-src={images.realEstate.publicURL}
                    className="card-img-top rounded-0 lazyload"
                    alt="Real Estate"
                    width="255"
                    height="200"
                  />
                </Link>
                <div className="card-body px-2 py-3">
                  <div className="h4">Real estate</div>
                  <p className="card-text">
                    Eliminate the lengthy paperwork and close deals faster for
                    real estate firms as well as individual buyers and sellers.
                  </p>
                  <Link to="/real-estate/" className="card-link ani-link">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            <div className="col ani-in show-on-scroll mb-4">
              <div className="card border-0">
                <Link to="/finance/" className="stretched-link">
                  <img
                    data-src={images.finance.publicURL}
                    className="card-img-top rounded-0 lazyload"
                    alt="Finance"
                    width="255"
                    height="200"
                  />
                </Link>
                <div className="card-body px-2 py-3">
                  <div className="h4">Finance</div>
                  <p className="card-text">
                    Successfully streamline financial consultants and asset
                    management firms with our free eSignature services.
                  </p>
                  <Link to="/finance/" className="card-link ani-link">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            <div className="col ani-in show-on-scroll mb-4">
              <div className="card border-0">
                <Link to="/legal/" className="stretched-link">
                  <img
                    data-src={images.legal.publicURL}
                    className="card-img-top rounded-0 lazyload"
                    alt="Legal"
                    width="255"
                    height="200"
                  />
                </Link>
                <div className="card-body px-2 py-3">
                  <div className="h4">Legal</div>
                  <p className="card-text">
                    Improve compliance and efficiency across government and
                    private organisations for legal and law enforcement
                    professionals.
                  </p>
                  <Link to="/legal/" className="card-link ani-link">
                    Read more
                  </Link>
                </div>
              </div>
            </div>

            <div className="col ani-in show-on-scroll mb-4">
              <div className="card border-0">
                <Link to="/human-resource/" className="stretched-link">
                  <img
                    data-src={images.humanResources.publicURL}
                    className="card-img-top rounded-0 lazyload"
                    alt="Human Resources"
                    width="255"
                    height="200"
                  />
                </Link>
                <div className="card-body px-2 py-3">
                  <div className="h4">Human resources</div>
                  <p className="card-text">
                    Simplify the process for HR professionals by saving time and
                    effort behind manually creating any new hire paperworks.
                  </p>
                  <Link to="/human-resource/" className="card-link ani-link">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
